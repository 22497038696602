<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Edit Task Block Component
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="taskBlockEditForm"
        #default="{invalid}"
      >
        <b-card
          class="px-3 py-2"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Component Name*"
                label-for="h-task-block-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Component"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-task-block-name"
                    v-model="name"
                    placeholder="Component Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="Icon*"
                label-for="h-task-block-icon"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Icon"
                  vid="icon"
                  rules="required"
                >
                  <b-form-input
                    id="h-task-block-icon"
                    v-model="icon"
                    placeholder="Icon"
                    :state="(errors.length > 0 || iconValidation) ? false : null"
                    name="icon"
                    @input="iconValidation == true ? iconValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="iconValidation"
                    class="text-danger"
                  >
                    {{ iconError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-4">
              <validation-provider
                #default="{ errors }"
                name="Color"
                vid="color"
                rules="required"
              >
                <b-form-group
                  label="Color Scheme*"
                  label-for="h-activity-type-color"
                  label-cols-md="4"
                  :state="(errors.length > 0 || colorValidation) ? false : null"
                >
                  <b-form-input
                    id="h-activity-type-color"
                    v-model="color"
                    type="color"
                    :state="(errors.length > 0 || colorValidation) ? false : null"
                    name="color"
                    @input="colorValidation == true ? colorValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="colorValidation"
                    class="text-danger"
                  >
                    {{ colorError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Action*"
                label-for="h-task-block-action"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Action"
                  vid="action"
                  rules="required"
                >
                  <b-form-input
                    id="h-task-block-action"
                    v-model="action"
                    placeholder="Action"
                    :state="(errors.length > 0 || actionValidation) ? false : null"
                    name="action"
                    @input="actionValidation == true ? actionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="actionValidation"
                    class="text-danger"
                  >
                    {{ actionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-task-block-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-task-block-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
          class="px-3 py-2"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Fields
            </h3>
          </template>
          <b-row
            v-for="(field, index) in customFields"
            :key="index"
            ref="row"
          >
            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                name="Input Type"
                vid="inputType"
                rules="required"
              >
                <b-form-group
                  label="Component Type*"
                  :label-for="'h-task-block-input-type' + index"
                  label-cols-md="4"
                  :state="(errors.length > 0 || field.typeInvalid) ? false : null"
                >
                  <v-select
                    :id="'h-task-block-input-type' + index"
                    v-model="field.type"
                    label="title"
                    :options="inputTypeOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="updateExtraFileds(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.typeInvalid"
                    class="text-danger"
                  >
                    {{ field.typeInvalidMessage }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" />

            <b-col cols="12">
              <div
                class="row"
              >
                <b-col cols="6">
                  <b-form-group
                    label="Label*"
                    :label-for="'h-task-block-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-task-block-label' + index"
                        v-model="field.label"
                        placeholder="Field Label"
                        :state="(errors.length > 0 || field.labelInvalid) ? false : null"
                        name="label"
                        @input="field.labelInvalid == true ? field.labelInvalid = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelInvalid"
                        class="text-danger"
                      >
                        {{ field.labelInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" />
                <b-col cols="6">
                  <b-form-group
                    label="Placeholder"
                    :label-for="'h-task-block-placeholder' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Placeholder"
                      vid="placeholder"
                      rules=""
                    >
                      <b-form-input
                        :id="'h-task-block-placeholder' + index"
                        v-model="field.placeholder"
                        placeholder="Field Placeholder"
                        :state="(errors.length > 0 || field.placeholderInvalid) ? false : null"
                        name="placeholder"
                        @input="field.placeholderInvalid == true ? field.placeholderInvalid = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.placeholderInvalid"
                        class="text-danger"
                      >
                        {{ field.placeholderInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" />
                <b-col cols="8">
                  <b-form-group
                    label="Description"
                    :label-for="'h-task-block-description' + index"
                    label-cols-md="3"
                  >
                    <b-form-textarea
                      :id="'h-task-block-description' + index"
                      v-model="field.description"
                      placeholder="Description"
                      rows="3"
                      name="description"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4" />

                <b-col
                  v-if="field.type == 'select' || field.type == 'multi-select'"
                  cols="6"
                >
                  <b-form-group
                    label="Add Selection Option*"
                    :label-for="'h-task-block-extraOptions' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      v-for="(opt, key) in field.extraOptions"
                      :key="key"
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <div class="inlineElement mb-2">
                        <b-form-input
                          :id="'h-task-block-label' + index + 'extraOptionsKey' + key"
                          v-model="opt.key"
                          placeholder="Type an option Name"
                          :state="(errors.length > 0 || opt.keyValidation) ? false : null"
                          name="key"
                          class="mr-2"
                          @input="opt.keyValidation == true ? opt.keyValidation = false : null"
                        />
                        <b-form-input
                          :id="'h-task-block-label' + index + 'extraOptions' + key"
                          v-model="opt.value"
                          placeholder="Add a text to display"
                          :state="(errors.length > 0 || opt.valueValidation) ? false : null"
                          name="value"
                          @input="opt.valueValidation == true ? opt.valueValidation = false : null"
                        />
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-secondary"
                          type="button"
                          class="btn-icon rounded-circle"
                          @click="removeCustomFieldOptions(index, key)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            size="18"
                          />
                        </b-button>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="opt.valueValidation"
                        class="text-danger"
                      >
                        {{ opt.valueValidationMessage }}
                      </small>
                    </validation-provider>
                    <p
                      class="addOptionText"
                      @click="addCustomFieldOptions(index)"
                    >
                      Add an Option
                    </p>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="field.type == 'select' || field.type == 'multi-select'"
                  cols="6"
                />

                <b-col cols="6">
                  <b-form-group
                    label="Required?*"
                    :label-for="'h-task-block-required' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Required"
                      vid="required"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-task-block-required' + index"
                        v-model="field.required"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.required === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.requiredInvalid"
                        class="text-danger"
                      >
                        {{ field.requiredInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" />
              </div>
            </b-col>

            <b-col cols="12">
              <p
                class="removeText"
                @click="removeCustomField(index)"
              >
                Remove this Field
              </p>

              <hr class="dividerHR">
            </b-col>
          </b-row>
          <b-button
            v-if="customFields.length > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            @click="addCustomField"
          >
            <span class="align-middle">Add Field</span>
          </b-button>
          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <h4>No item yet</h4>
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="addCustomField"
                >
                  <span class="align-middle">Add Field</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="cancelEdit"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BFormRadio, BFormRadioGroup, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup,
    BForm,
    BButton,
    BNav,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      icon: '',
      color: '#104D9D',
      action: '',
      status: 'active',
      nameError: 'Valid name is required',
      nameValidation: false,
      colorError: 'Valid color is required',
      colorValidation: false,
      actionError: 'Valid action is required',
      actionValidation: false,
      iconError: 'Valid icon is required',
      iconValidation: false,
      statusValidation: false,
      statusError: 'Valid status is required',
      trueFalseOptions: [{ title: 'Yes', code: 'yes' }, { title: 'No', code: 'no' }],
      inputTypeOptions: [
        { title: 'Text', code: 'text' },
        { title: 'Normal Textarea', code: 'textarea-normal' },
        { title: 'Rich Textarea', code: 'textarea-rich' },
        { title: 'Select', code: 'select' },
        { title: 'Multiple Select', code: 'multi-select' },
      ],
      customFields: [],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      multiSelectOptions: [
        { text: 'One Option Only', value: 'single' },
        { text: 'Multiple Options', value: 'multiple' },
      ],
      statusOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],

      // validation rules
      required,
    }
  },
  created() {
    this.$http.get(`operation/task-block-components/${this.$route.params.id}`)
      .then(response => {
        this.name = response.data.name ?? ''
        this.icon = response.data.icon ?? ''
        this.color = response.data.color ?? '#104D9D'
        this.action = response.data.action ?? ''
        this.status = response.data.status ?? 'active'
        const fullData = response.data.fields.map(field => {
          const extraOptionsData = field.extraOptions.map(option => {
            const optionData = {
              key: option.key,
              keyInvalid: false,
              keyInvalidMessage: '',
              value: option.value,
              valueInvalid: false,
              valueInvalidMessage: '',
            }
            return optionData
          })

          const properties = {
            type: field.type,
            label: field.label,
            placeholder: field.placeholder,
            description: field.description,
            required: field.required,
            extraOptions: extraOptionsData,
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            descriptionInvalid: false,
            descriptionInvalidMessage: '',
          }
          return properties
        })

        this.customFields = fullData ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    addCustomField() {
      this.customFields.push({
        type: 'text',
        label: '',
        placeholder: '',
        description: '',
        required: 'yes',
        extraOptions: [],
        typeInvalid: false,
        typeInvalidMessage: '',
        placeholderInvalid: false,
        placeholderInvalidMessage: '',
        labelInvalid: false,
        labelInvalidMessage: '',
        requiredInvalid: false,
        requiredInvalidMessage: '',
        descriptionInvalid: false,
        descriptionInvalidMessage: '',
      })
    },
    addCustomFieldOptions(index) {
      this.customFields[index].extraOptions.push({
        key: '',
        keyInvalid: false,
        keyInvalidMessage: '',
        value: '',
        valueInvalid: false,
        valueInvalidMessage: '',
      })
    },
    updateExtraFileds(index) {
      // eslint-disable-next-line no-unused-expressions
      this.customFields[index].typeInvalid === true ? this.customFields[index].typeInvalid = false : null
      if (this.customFields[index].type === 'select' || this.customFields[index].type === 'multi-select') {
        this.customFields[index].extraOptions.push({
          key: '',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: '',
          valueInvalid: false,
          valueInvalidMessage: '',
        })
      } else {
        this.customFields[index].extraOptions = []
      }
    },
    removeCustomFieldOptions(index, key) {
      if (this.customFields[index].extraOptions.length > 1) {
        this.customFields[index].extraOptions.splice(key, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeCustomField(index) {
      this.customFields.splice(index, 1)
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'operation-task-block-components-index' })
          }
        })
    },
    submitForm() {
      this.$refs.taskBlockEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('color', this.color)
          formData.append('action', this.action)
          formData.append('icon', this.icon)
          formData.append('status', this.status)
          const trimmedData = this.customFields.map(field => {
            const extraOptionsData = field.extraOptions.map(option => {
              const optionData = {
                key: option.key,
                value: option.value,
              }
              return optionData
            })

            const properties = {
              type: field.type,
              placeholder: field.placeholder,
              label: field.label,
              description: field.description,
              required: field.required,
              extraOptions: extraOptionsData,
            }
            return properties
          })

          formData.append('fields', JSON.stringify(trimmedData))

          this.$http.patch(`operation/task-block-components/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Changes have Saved.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'operation-task-block-components-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'icon') {
                    this.iconError = validationError.msg
                    this.iconValidation = true
                  } else if (validationError.param === 'color') {
                    this.colorError = validationError.msg
                    this.colorValidation = true
                  } else if (validationError.param === 'action') {
                    this.actionError = validationError.msg
                    this.actionValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'fields') {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: validationError.msg || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
</style>
